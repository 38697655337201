import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {html} from 'lit';
import {
    toastProgressWrapper,
} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper.ts';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper.ts';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form.ts';
import {Auth} from '../controllers/Auth.ts';
import {userCall} from '../helpers/UserCallHelper.ts';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';

@customElement('component-delete-account')
class ComponentDeleteAccount extends BunnyElement {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <h2>
                Please delete my account
            </h2>

            <component-native-form @submit="${this.sendCallback}" style="--loading-inset: -500px">
                <component-input-textarea
                        name="reason"
                        label="Reason for leaving?  "
                        placeholder="Have you had any issues with us that have led you to deleting your account?"
                        autofocus></component-input-textarea>

                <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                    Submit
                </button>

                <p>
                    <component-input-checkbox required name="terms">
                        I understand that deleting my account means all information attached to it will be lost or
                        inaccessible, including but not limited to unused points, order history, and ticket numbers.
                    </component-input-checkbox>
                </p>

                <div slot="submitButton">
                    <component-button id="authLogin" raised
                                      style="display: block; background-color: var(--attention-color); color: white; margin-left: -15px; margin-right: -15px; text-align: center; border-radius: 0">
                        Delete account
                    </component-button>
                </div>
            </component-native-form>
        `;
    }

    @toastProgressWrapper({
        successMessage: 'Account deleted',
        progressMessage: 'Deleting account',
        failedMessage: 'Failed deleting account: {{e}}',
    })
    @bind()
    async sendCallback(e: FormSubmitEvent) {
        await Auth.getInstance().reauthenticate();

        await userCall('AuthDeleteAccount', {
            leaveReason: e.detail.data.reason,
        });

        await delayPromise(500);
        location.reload();
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-delete-account': ComponentDeleteAccount;
    }
}