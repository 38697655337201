import {AuthPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './components/component-login';
import './components/component-login-one-time';
import './components/component-recover-account';
import './components/component-signup';
import './components/component-logout';
import './components/component-account-profile-picture';
import './components/component-recover-account-reset-password';
import './components/component-recover-account-reset-email-address';
import './components/component-account-details';
import './components/component-account-password';
import './components/component-account-email-address';
import {EVENT__INTERNAL_APP_READY} from '../../__internal/local/helpers/SetupAppReadyEvent';
import {addRegisterMeta} from './helpers/AccountHelper';
import {FIRESTORE_COLLECTION_ACCOUNTS} from '../shared/helpers/FirebaseHelper';
import './components/component-account-manage-auth';
import './components/component-admin-auth-account-delete';
import './components/component-admin-auth-account-details';
import './components/component-admin-auth-methods';
import './components/component-admin-auth-roles';
import './components/component-admin-auth-tracking';
import './components/component-admin-auth-login-as';
import './components/component-admin-auth-shop-contact-details';
import './components/component-admin-auth-shop-contact-details-item';
import './components/component-reauthentication-password-dialog';
import './components/component-delete-account.ts'
import './tests/testing-components';

export class AuthLocalPackage extends AuthPackage implements LocalPackage {

    listen = {
        [EVENT__INTERNAL_APP_READY]() {
            let locationSearchParams = new URL(location.href).searchParams;

            let urlReferer = locationSearchParams.get('referer');
            if (urlReferer && urlReferer.length === 28) {
                addRegisterMeta('referer', {
                    path: `${FIRESTORE_COLLECTION_ACCOUNTS}/${urlReferer}`,
                    id: urlReferer,
                });
            }


            let utmValues = {
                utm_source: locationSearchParams.get('utm_source'),
                utm_medium: locationSearchParams.get('utm_medium'),
                utm_campaign: locationSearchParams.get('utm_campaign'),
                utm_term: locationSearchParams.get('utm_term'),
                utm_content: locationSearchParams.get('utm_content'),
            };
            if (Object.values(utmValues).filter(_ => _).length) {
                addRegisterMeta('landingUtm', utmValues);
            }
        },
    };

}
